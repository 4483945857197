<template>
  <div class="user">
    <page-search
      ref="pageSearchRef"
      :searchFormConfig="searchFormConfig"
      :pageTitle="$t('general.router-monitor-status')"
      @resetBtnClick="handleResetClick"
      @queryBtnClick="handleQueryClick"
    >
    </page-search>
    <page-content
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #onlineStatus="scope">
        <div class="status-wrap" v-if="scope.row.online">
          <div class="circle online-circle"></div>
          Online
        </div>
        <div class="status-wrap" v-else>
          <div class="circle offline-circle"></div>
          Offline
        </div>
      </template>
      <!-- <template #memory>
        <el-progress class="memory-progress" type="circle" :percentage="50">
          <template #default="{ percentage }">
            <span class="percentage-value">{{ percentage }}%</span>
            <span class="percentage-label">4778/12224</span>
          </template>
        </el-progress>
      </template>
      <template #battery="scope">
        <el-progress
          :text-inside="true"
          :stroke-width="26"
          :percentage="scope.row.batteryInfo"
        />
      </template> -->
      <template #otherHandler="scope">
        <el-button
          v-if="scope.row.status === '01'"
          class="opt-btn"
          icon="el-icon-view"
          size="mini"
          type="success"
          plain
          @click="handleViewClick(scope.row.sn)"
          >{{ $t('general.view') }}</el-button
        >
        <el-button
          class="opt-btn"
          icon="el-icon-position"
          type="warning"
          size="mini"
          plain
          >Terminal Map</el-button
        >
      </template>
    </page-content>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { searchFormConfig } from './config/search'
import { contentTableConfig } from './config/table'
import pageSearch from '@/components/PageSearch/page-search'
import pageContent from '@/components/PageContent/page-content.vue'
import { cloneDeep } from 'lodash' // 导入loadsh插件

const tableOptions = {
  moduleName: 'monitor', // 所属的模块
  pageName: 'monitorStatus', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: false, // 是否显示添加按钮的插槽
  isShowEditBtn: false,
  isShowDeleteBtn: false,
  isShowViewBtn: false,
  pageAction: 'monitor/getMonitorStatusList', // 获取表格的Action
  deleteRecordAction: 'monitor/handleDeleteTerminal', // 删除record的Action
  pageCountGetter: 'monitor/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'monitor/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)
const pageSearchRef = ref('')

// 发送网络请求
const pageContentRef = ref()
const handleResetClick = () => {
  pageContentRef.value.getPageData()
}

const formateData = (data) => {
  const formateObject = cloneDeep(data)
  formateObject.tenantId = data.tenantId ? data.tenantId.join(',') : ''
  return formateObject
}

const handleQueryClick = (queryInfo) => {
  pageContentRef.value.getPageData(formateData(queryInfo))
}

const router = useRouter()
const handleViewClick = (sn) => {
  router.push(`/monitor/status/detail/${sn}`)
}

const pageInfoChangeAction = () => {
  const formData = pageSearchRef.value.getCurrentFieldValues()
  pageContentRef.value.getPageData(formateData(formData))
}
</script>

<!-- <style>
.memory-progress .el-progress-circle {
  width: 70px !important;
  height: 70px !important;
}
</style> -->
<style lang="scss" scoped>
.status-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
}
.online-circle {
  background-color: green;
}
.offline-circle {
  background-color: #ff0000;
}

// .percentage-label {
//   font-size: 10px;
//   display: block;
// }
// .percentage-value {
//   font-size: 13px;
//   display: block;
// }
</style>

import {
  getCommonParamsItemLabel
  // getGlobalParamsListItemLabel
} from '@/utils/common'

export const contentTableConfig = {
  title: '',
  // title: ('common.app-terminal-online-list'),
  propList: [
    { prop: 'sn', label: 'terminal.sn', width: 200 },
    {
      prop: 'online',
      label: 'Online Status',
      slotName: 'onlineStatus'
      // formatter: (row) => {
      //   return row ? 'Online' : 'Offline'
      // }
    },
    {
      prop: 'ip',
      label: 'log.ip-address',
      width: '150'
    },
    {
      prop: 'tenantName',
      label: 'file.tenant',
      width: '150'
    },
    {
      prop: 'merchantName',
      label: 'common.app-merchant-name'
    },
    {
      prop: 'brandName',
      label: 'terminal.brand',
      width: '120'
    },
    {
      prop: 'modelName',
      label: 'file.model-type'
      // formatter: (row) => {
      //   return getGlobalParamsListItemLabel(row, 'terminalModel', {
      //     isNeedPreFix: false
      //   })
      // }
    },
    {
      prop: 'tmsVersion',
      label: 'monitor.tms-service'
    },
    {
      prop: 'status',
      label: 'common.app-status',
      width: '140',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'terminal_status')
      }
    },
    {
      prop: 'updatedTime',
      label: 'Last Report Time',
      slotName: 'formatZeroTime'
    },
    {
      label: 'common.app-operation',
      width: '220',
      slotName: 'handler'
    }
  ],
  showIndexColumn: true,
  showSelectColumn: false
}
